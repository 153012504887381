.container {
    display: flex;
    flex-direction: column;
    padding: 64px 0 0;
}
.row {
    display: flex;
    flex-direction: row;
}
.children {
    width: 100%;
}
  