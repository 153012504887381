@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');

* {
  font-family: 'Roboto', sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  background: #f6f8ff;
}

fieldset {
  border: 0;
  padding: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
