.legend {
  color: #666;
  margin-left: 1rem;
}

.copyIcon {
  border: none;
  background: none;
  margin-left: auto;
  cursor: pointer;
}

.tableWrapper {
  max-height: 300px;
}

thead tr th {
  top: 0;
  position: sticky;
  text-align: left;
  background: #fafafa;
  box-shadow: 0px 0px 0 2px #e8e8e8;
}
