.inputImgStyle {
    width:  15px;
    height: 15px;
}

.labelImg{
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.labelTitle{
    padding-top: 15px;
}